.cabinet__container {
     width: 100%;
     display: flex;
     flex-direction: column;
     align-items: center;
     padding: 2% 4%;
     min-height: 100vh;
}

.cabinet__navbar {
     width: 100%;
     height: 70px;
     display: flex;
     align-items: center;
     justify-content: space-between;
     background-color: white;
     padding: 0 2%;
     box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
     border-radius: 8px;
     margin-bottom: 20px;
     color: white;
}

.cabinet__navbar p {
     font-size: 24px;
     font-weight: 300;
     margin: 0;
}

.cabinet__navbar button {
     width: 151px;
     height: 40px;
     background-color: #8B0000;
     color: white;
     font-size: 22px;
     border-radius: 8px;
     border: none;
     outline: none;
     font-weight: 200;
     cursor: pointer;
}

.cabinet__navbar button:hover {
     background-color: #e04343;
}

.cabinet__navbar button:active {
     background-color: #d03434;
}

@media(max-width: 480px) {
     .cabinet__navbar p {
          font-size: 18px;
     }

     .cabinet__navbar button {
          width: 100px;
          height: 35px;
          font-size: 16px;
     }

     
}