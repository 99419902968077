.input__container {
     position: relative;
     /* Это важно для позиционирования выпадающего списка */
     width: 500px;
     height: 41px;
     background-color: white;
     display: flex;
     align-items: center;
     justify-content: space-between;
     border-radius: 8px;
     border: solid 1px darkred;
}

.input__container>input {
     width: 400px;
     height: 38px;
     border: none;
     outline: none;
     text-indent: 8px;
     font-size: 18px;
     color: #222222;
     background: none;
     font-weight: 200;
}

.input__container>input::placeholder {
     font-size: 18px;
     color: #22222267;
}

.input__container>button {
     width: 70px;
     height: 38px;
     background-color: #e447663f;
     border-radius: 8px;
     border: none;
     cursor: pointer;
     transform: scale(.98);
     transition: background-color ease-in .2s, transform ease-in .2s;
}

.input__container>button:hover {
     transform: scale(0.98);
     background-color: #f4003154;
}

/* Стили для выпадающего списка с результатами */
.product-list {
     position: absolute;
     /* Это позволяет располагать список относительно .input__container */
     top: 45px;
     /* Отступ сверху, чтобы располагаться под полем поиска */
     left: 0;
     width: 100%;
     max-height: 200px;
     /* Ограничение по высоте */
     overflow-y: auto;
     /* Скролл при большом количестве результатов */
     background-color: white;
     /* border: 1px solid #ddd; */
     border-radius: 8px;
     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
     z-index: 1000;
     /* Чтобы список был выше других элементов */
}

.product-list li {
     padding: 10px;
     list-style: none;
     cursor: pointer;
     font-size: 16px;
     border-bottom: 1px solid #ddd;
}
.product-list >li>a{
     color: #222222;
} 

.product-list li:last-child {
     border-bottom: none;
     /* Убираем границу у последнего элемента */
}

.product-list li:hover {
     background-color: #f9f9f9;
}

@media (min-width: 769px) and (max-width: 1024px) {
     .input__container {
          width: 250px;
          height: 36px;
     }

     .input__container>input {
          width: 170px;
          height: 35px;
     }

     .input__container>input::placeholder {
          font-size: 14px;
     }

     .input__container>button {
          width: 55px;
          height: 35px;
     }

     .product-list {
          top: 40px;
          /* Коррекция отступа для других экранов */
     }
}