* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

body {
  height: 100vh;
  display: flex;
  justify-content: center;
  /* padding-top: 61px; */
background-color: white;
}



#root {
  max-width: 1920px;
  width: 100%;
}

.main {
  width: 100%;
  min-height: 100vh;
  padding-inline: 40px;
  padding-top: 1%;
  padding-bottom: 30px;
      /* padding-bottom: 100px; */
        /* Добавляем отступ под футер */
}

@media (max-width: 768px) {
  body {
    padding-top: 0px;
  }
        .main {
          padding-inline: 20px;
        }
}

