/* Контейнер */
.payment-container {
     width: 100%;
     height: 90vh;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     position: relative;
     animation: fadeIn 0.5s ease;
}

/* Кнопка "Назад" */
.back-button {
     position: absolute;
     top: 0px;
     left: 0px;
     background-color: transparent;
     border: none;
     cursor: pointer;
     padding: 10px;
     border-radius: 50%;
     transition: background-color 0.3s ease;
}

.back-button:hover {
     background-color: rgba(0, 0, 0, 0.1);
}

/* Итоговая сумма */
.payment-summary {
     font-size: 1.5rem;
     color: #00b894;
     font-weight: 300;
     margin-bottom: 18px;
     text-align: center;
     border-bottom: 2px solid #dfe6e9;
}

/* Форма */
.payment-form {
     display: flex;
     flex-direction: column;
     width: 100%;
     max-width: 380px;
     background-color: #fff;
     padding: 25px;
     border-radius: 12px;
     box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
     animation: slideUp 0.5s ease;
}

/* Поля ввода */
.payment-form label {
     margin-bottom: 10px;
     color: #222222;
     font-weight: 500;
     display: flex;
     align-items: start;
     flex-direction: column;
     gap: 4px;
}

.payment-form input {
     width: 100%;
     padding: 13px;
     font-size: .95rem;
     border: 1px solid #dfe6e9;
     border-radius: 8px;
     margin-bottom: 15px;
     transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.payment-form input:focus {
     border-color: #019974;
     box-shadow: 0 0 8px #39b89868;
     outline: none;
}

/* Кнопка оплаты */
.payment-button {
     padding: 12px;
     background-color: #00b894;
     color: #fff;
     border: none;
     border-radius: 8px;
     font-size: 1.1rem;
     cursor: pointer;
     transition: background-color 0.3s ease, transform 0.2s ease;
}

.payment-button:hover {
     background-color: #019974;
     transform: translateY(-2px);
}

.payment-button:active {
     transform: translateY(0);
}

/* Анимации */
@keyframes fadeIn {
     0% {
          opacity: 0;
     }

     100% {
          opacity: 1;
     }
}

@keyframes slideUp {
     0% {
          transform: translateY(20px);
          opacity: 0;
     }

     100% {
          transform: translateY(0);
          opacity: 1;
     }
}

/* Адаптивность */
@media (max-width: 768px) {
     .payment-container {
          height: 90vh;
     }

     .payment-form {
          padding: 20px;
     }

     .payment-button {
          font-size: 1rem;
          padding: 12px;
     }
}

@media (max-width: 480px) {
     .payment-title {
          font-size: 1.5rem;
     }

     .payment-form {
          padding: 15px;
     }

     .payment-button {
          padding: 10px;
     }
}