/* Основные стили для контейнера продуктов */
.product__big-container {
     min-height: 100vh;
     margin-top: 3%;
}

.product__container {
     display: grid;
     grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
     gap: 1.25rem;
     width: 100%;
     margin: 0 auto;

}
/* Стили для карточек продуктов */
.product__card {
     position: relative;
     background: rgba(255, 255, 255, 0.85);
     backdrop-filter: blur(6px);
     border: 1px solid rgba(0, 0, 0, 0.1);
     /* Тонкая и сдержанная граница */
     border-radius: 0.5rem;
     overflow: hidden;
     transition: all 0.2s ease;
}

.product__card:hover {
     transform: translateY(-1px);
     box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.08), 0 2px 4px -1px rgba(0, 0, 0, 0.04);
}

/* Контейнер изображения */
.product__image-container {
     position: relative;
     width: 100%;
     padding-top: 100%;
     /* Соотношение сторон 1:1 */
     overflow: hidden;
     background: #f8fafc;
}

.product-image {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     object-fit: cover;
     transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.product__card:hover .product-image {
     transform: scale(1.03);
}

/* Информация о продукте */
.product__info {
     padding: 0.875rem;
}

.product__name {
     font-size: 0.975rem;
     font-weight: 600;
     color: #1e293b;
     margin-bottom: 0.375rem;
     line-height: 1.3;
     display: -webkit-box;
     -webkit-line-clamp: 2;
     -webkit-box-orient: vertical;
     overflow: hidden;
     text-overflow: ellipsis;
}

.product__quantity {
     font-size: 0.75rem;
     color: #64748b;
     margin-bottom: 0.375rem;
}

.product__price {
     font-size: 1.125rem;
     font-weight: 700;
     color: #0f172a;
     margin-bottom: 0.875rem;
}

/* Контейнер для действий */
.product__actions {
     display: flex;
     gap: 0.625rem;
     padding-top: 0.875rem;
     border-top: 1px solid #e2e8f0;
}

/* Кнопка корзины */
.product__cart-button {
     flex: 1;
     display: flex;
     align-items: center;
     justify-content: center;
     gap: 0.375rem;
     background: #8B0000;
     color: white;
     font-size: 0.7rem;
     font-weight: 500;
     padding: 0.3rem 0.4rem;
     border: none;
     border-radius: 0.5rem;
     cursor: pointer;
     transition: all 0.2s ease;
}

.product__cart-button:hover {
     background: #6e0000;
}

.product__cart-icon {
     font-size: 1rem;
}

/* Ссылка Подробнее */
.product__details-link {
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 0.3rem 0.4rem;
          background: #f9e6e6;
     color: #8B0000;
     font-size: 0.7rem;
     font-weight: 500;
     text-decoration: none;
     border-radius: 0.5rem;
     transition: all 0.2s ease;
}

.product__details-link:hover {
     background: #f2c8c8;
     color: #6e0000;
}

/* Адаптация для мобильных устройств */
@media (max-width: 1024px) {
     .product__container {
          grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
          gap: 1rem;
     }
}

@media (max-width: 768px) {
     .product__big-container {
          padding: 1rem;
     }

     .product__container {
          grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
          gap: 0.875rem;
     }

     .product__info {
          padding: 0.75rem;
     }
}

@media (max-width: 480px) {
     .product__container {
          grid-template-columns: repeat(2, 1fr);
          gap: 0.75rem;
     }

     .product__name {
          font-size: 0.875rem;
     }

     .product__price {
          font-size: 1rem;
     }

     .product__actions {
          flex-direction: row;
          gap: 0.4rem;
     }

.product__cart-button,
.product__details-link {
     width: auto;
     padding: 0.1rem 0.2rem;
     /* Уменьшено до 0.1rem 0.2rem */
     font-size: 0.5rem;
     /* Уменьшено до 0.5rem */
}

.product__cart-icon {
     font-size: 5px;
  
}
}

.loader-container {
     display: flex;
     justify-content: center;
     align-items: center;
     height: 100vh;
}