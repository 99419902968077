.simple-swiper {
     width: 100%;
     height: 440px;
     position: relative;
     /* Плавный переход */
          border-radius: 10px;
}

.image-container {
     height: 100%;
     position: relative;
     overflow: hidden;
     /* Избавляемся от зазоров */
}

.slider-image {
     width: 100%;
     height: 100%;
     object-fit: cover;
     transition: transform 1s ease;
   
}

.custom-prev,
.custom-next {
     position: absolute;
     top: 50%;
     transform: translateY(-50%);
     z-index: 10;
     color: white;
     font-size: 2.5rem;
     font-weight: bold;
     padding: 10px;
     cursor: pointer;
     transition: transform 0.3s ease;
}

.custom-prev {
     left: 2%;
}

.custom-next {
     right: 2%;
}

@media (max-width: 640px) {
     .simple-swiper {
          width: 100%;
          height: 180px;
     }

     .custom-prev,
     .custom-next {
          font-size: 2rem;
     }
}