.home__container {
     width: 100%;
     display: flex;
     justify-content: center;
     flex-direction: column;
}

.title__project {
     width: 100%;
     display: flex;
     align-items: end;
     justify-content: end;
     margin-bottom: 20px;
}

.title__project>a {
     font-size: 30px;
     font-weight: 100;
     color: #222222;
}

.title__project>a {
     display: none;
}

@media (max-width: 768px) {
     .title__project>a {
          display: block;
     }
}