/* Общие стили для десктопа */
.navbar__container {
     width: 100%;
     height: 81px;
     display: flex;
     align-items: center;
     justify-content: space-between;
     padding-inline: 40px;
     background-color: darkred;
     z-index: 9999;
}

.navbar__container>a {
     font-size: 30px;
     font-weight: 100;
     color: white;
}

.navbar__catalog-input {
     display: flex;
     align-items: center;
     justify-content: center;
     gap: 7px;
}

.navbar__catalog {
     width: 151px;
     height: 40px;
     display: flex;
     align-items: center;
     justify-content: center;
     gap: 2px;
     background-color: rgba(255, 255, 255, 0.217);
     border-radius: 8px;
     transform: scale(.98);
     transition: background-color ease-in .3s, transform ease-in .2s;
     cursor: pointer;
}

.navbar__catalog:hover {
     background-color: rgba(255, 255, 255, 0.418);
     transform: scale(0.98);
}

.navbar__catalog>span {
     color: white;
     font-size: 18px;
     font-weight: 200;
}

.navbar__a-links {
     display: flex;
     align-items: center;
     justify-content: center;
     gap: 17px;
}

.navbar__a-link {
     display: flex;
     align-items: center;
     justify-content: center;
     gap: 3px;
     cursor: pointer;
}

.navbar__a-link>span {
     color: white;
     font-size: 18px;
     font-weight: 200;
}

.navbar__catalog-app{
     display: none ;
}
.navbar__a-link-home {
     display: none;
}

/* Адаптивные стили для планшетов */
@media (min-width: 769px) and (max-width: 1024px) {

     /* Контейнер навбара */
     .navbar__container {
          height: 70px;
          padding-inline: 50px;
          justify-content: space-between;
          background-color: darkred;
          /* Немного темнее цвет для планшетов */
     }

     /* Название платформы */
     .navbar__container>h1 {
          font-size: 26px;
          /* Уменьшаем немного размер */
     }

     /* Стили для каталога и Input */
     .navbar__catalog-input {
          gap: 10px;
          /* Увеличим немного расстояние между элементами */
     }

     .navbar__catalog {
          width: 140px;
          height: 35px;
          border-radius: 6px;
          transition: background-color ease-in .3s, transform ease-in .2s;
     }

     .navbar__catalog>span {
          font-size: 16px;
          /* Немного уменьшаем текст */
     }

     /* Ссылки и иконки */
     .navbar__a-links {
          gap: 15px;
          /* Немного уменьшаем расстояние между иконками */
     }

     .navbar__a-link {
          gap: 5px;
     }

     .navbar__a-link>span {
          font-size: 16px;
     }

     .navbar__a-link>svg {
          font-size: 22px;
     }
}

/* Адаптивные стили для планшетов (769px - 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {

     /* Контейнер навбара */
     .navbar__container {
          height: 65px;
          background-color: darkred;
          /* Темный фон для акцента */
          justify-content: space-between;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          /* Теневой эффект для глубины */
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          /* Легкая граница */
     }

     /* Название платформы */
     .navbar__container>h1 {
          font-size: 22px;
          /* Уменьшаем текст */
          color: #fff;
          letter-spacing: 1px;
          /* Добавляем пространство между буквами */
     }

     /* Стили для каталога и Input */
     .navbar__catalog-input {
          gap: 8px;
          /* Уменьшаем пространство между элементами */
     }

     .navbar__catalog {
          height: 35px;
          background-color: rgba(255, 255, 255, 0.2);
          /* Прозрачный фон */
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s ease, transform 0.2s ease;
     }



     .navbar__catalog>span {
          font-size: 14px;
          /* Уменьшаем размер текста */
          color: white;
          text-transform: uppercase;
          /* Делаем текст заглавными для креативности */
     }

     .navbar__catalog>svg {
          font-size: 20px;
     }



     /* Ссылки и иконки */
     .navbar__a-links {
          gap: 12px;
          /* Уменьшаем расстояние между ссылками */
     }

     .navbar__a-link {
          gap: 4px;
          /* Уменьшаем пространство между иконкой и текстом */
     }

     .navbar__a-link>span {
          font-size: 14px;
          /* Уменьшаем текст */
          color: #fff;
          font-weight: 300;
     }

     /* Размер иконок */
     .navbar__a-link>svg {
          font-size: 20px;
          /* Уменьшаем размер иконок */
          transition: transform 0.2s ease;
     }

     .navbar__a-link:hover>svg {
          transform: scale(1.1);
          /* Легкое увеличение иконки при наведении */
     }

     /* Увеличение расстояния от нижней части экрана */
     .navbar__container {
          margin-bottom: 10px;
     }

}

/* Адаптивные стили для десктопа */
@media (min-width: 769px) {
     .navbar__container {
          /* position: fixed;
          top: 0;
          left: 0;
          right: 0; */
     }

}

/* Адаптивные стили для мобильной версии */
@media (max-width: 768px) {
     .navbar__container {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          height: 60px;
          background-color: darkred;
          /* Полупрозрачный фон */
          display: flex;
          justify-content: space-between;
          /* padding-inline: 20px; */
          transition: bottom 0.3s ease-in-out;
          padding-inline: 20px;
          /* Для анимации скрытия/показа */
     }

     /* Скрываем название и каталог */
     .navbar__container>a,
     .navbar__input-search,
     .navbar__catalog {
          display: none;
     }

     .navbar__catalog-app {
          display: flex;
               align-items: center;
               justify-content: space-between;
               flex-direction: column;
               gap: 2px;
               transform: scale(.98);
               transition: background-color ease-in .3s, transform ease-in .2s;
               cursor: pointer;

     }

     .navbar__catalog-app>span {
          font-size: 12px;
          /* Уменьшаем текст */
          color: white;
          font-weight: 300;
     }

     /* Размер иконок */
     .navbar__catalog-app>svg {
          font-size: 27px;
               transition: transform 0.2s ease;
     }

           /* home */
           .navbar__a-link-home {
               display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: column;
                    gap: 2px;
                    transform: scale(.98);
                    transition: background-color ease-in .3s, transform ease-in .2s;
                    cursor: pointer;
           }
     
           .navbar__a-link-home>span {
                font-size: 12px;
                /* Уменьшаем текст */
                color: white;
                font-weight: 300
           }
     
           .navbar__a-link-home>svg {
                font-size: 27px;
                transition: transform 0.2s ease;
           }



     /* Перенастройка ссылок */
     .navbar__a-links {
          width: 100%;
          align-items: center;
          justify-content: space-between;
          /* padding: 0 40px; */
     }

     .navbar__a-link {
          flex-direction: column;
     }

     .navbar__a-link>span {
          font-size: 12px;
     }

     /* Увеличиваем иконки для лучшей видимости */
     .navbar__a-link>svg {
          font-size: 24px;
     }
}


.navbar__hide {
     bottom: -60px;
     /* Скрываем навбар, сдвигая вниз */
}

.navbar__show {
     bottom: 0;
     /* Показываем навбар */
}

/* Добавляем отступ для контента, чтобы избежать перекрытия */
body {
     padding-bottom: 10px;
     /* Добавляем отступ снизу, равный высоте навбара */
}

@media (max-width: 500px) {
     .navbar__container {
          padding-inline: 20px;
     }
}